import { DEFAULT_INVALID_PAGE_MESSAGE } from "./Utils/constant";

interface ErrorPageProps {
  status: number;
  customMessage?: string;
}

const Error = ({ status, customMessage }: ErrorPageProps) => {
  return (
    <div className="flex flex-col-reverse items-center justify-center gap-8 px-4 py-[20px] md:py-20 md:px-20 lg:flex-row lg:px-24 lg:py-24">
      <div className="relative flex w-full flex-col lg:pb-0 lg:pt-16 xl:w-1/2">
        <h1 className="my-2 text-xl font-semibold text-gray-600 lg:text-2xl">
          {status} | {customMessage ?? DEFAULT_INVALID_PAGE_MESSAGE}
        </h1>
        <p className="my-2 text-xs text-gray-800 lg:text-base">
          Please contact{" "}
          <a href="labs-support@vertexholdings.com">
            labs-support@vertexholdings.com
          </a>{" "}
          for further support.
        </p>
      </div>
      <div>
        <img src="/connection.png" alt="" loading="lazy" />
      </div>
    </div>
  );
};

export const ErrorPage = ({ status, customMessage }: ErrorPageProps) => {
  return <Error status={status} customMessage={customMessage} />;
};
